import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/mdxPageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`last updated: 03-04-2012`}</h2>
    <p><strong parentName="p">{`Geosense`}</strong>{` is a client application designed for displaying location-based information on your Windows Phone.`}</p>
    <p>{`In order to provide the information for you, we may need to access your location information. As such, we are providing the following privacy policy. This privacy policy may be updated and you can receive information about updates through the website or the service.`}</p>
    <p>{`When you chose to enable location service, we collect the location of your phone and send it over the internet to get the location information.`}</p>
    <p>{`We do not store the current location information. You can turn off this feature in the settings and still use the application by entering a manual location.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      